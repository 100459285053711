<template>
  <b-card
    class="card-chart"
    :title="$t('therms.distribution')"
  >
    <vue-apex-charts
      type="donut"
      height="350"
      :options="chart.chartOptions"
      :series="chart.series"
    />
  </b-card>
</template>

<script>
import VueApexCharts from '@core/components/apex-chart/index.vue'
import { BCard } from 'bootstrap-vue'

export default {
  components: {
    VueApexCharts,
    BCard,
  },
  props: {
    series: {
      type: Array,
      required: true,
    },
    colors: {
      type: Array,
      required: true,
    },
    labels: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    chart() {
      const that = this

      return {
        series: this.series,
        chartOptions: {
          tooltip: {
            enabled: false,
          },
          legend: {
            show: true,
            position: 'bottom',
            fontSize: '12px',
            fontFamily: 'Roboto',
          },
          colors: this.colors,
          dataLabels: {
            enabled: true,
            formatter(val) {
              return `${window.numberFormat(val)} %`
            },
          },
          events: [],
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: '2rem',
                    fontFamily: 'Roboto',
                  },
                  value: {
                    fontSize: '1rem',
                    fontFamily: 'Roboto',
                    formatter(val) {
                      return `${window.numberFormat(val)} ${that.$t('units.kg')}`
                    },
                  },

                },
              },
            },
          },
          labels: this.labels,
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: '1.5rem',
                        },
                        value: {
                          fontSize: '1rem',
                        },
                        total: {
                          fontSize: '1.5rem',
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        },
      }
    },
  },
  mounted() {
  },
  methods: {
    /**
     * @param {Number|String} v
     * @returns {*}
     */
    numberFormat(v) {
      return window.numberFormat(v)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/base/bootstrap-extended/_include';
@import '@core/scss/base/components/_include';

.card.card-chart {
  .card-title {
    margin-bottom: 10px;
  }
}
</style>
