<template>
  <div>
    <h6 class="mt-2 pt-50 mb-1">
      {{ $t('therms.in-detail') }}
    </h6>
    <app-collapse
      type="border"
      class="animate__animated animate__fadeInUp"
    >
      <template
        v-if="sortedItems.length===0"
      >
        <app-collapse-item>
          <template #header>
            <span class="">{{ $t('pages.history.no-data') }}</span>
          </template>
        </app-collapse-item>
      </template>

      <template v-else>
        <app-collapse-item
          v-for="item in sortedItems"
          :key="item.id"
        >
          <template #header>
            <span class=""><strong class="text-primary">{{ $moment(item.datetime).format('LT') }}</strong> &nbsp; {{ item.crop.name }} ({{ item.crop_type.name }})</span>
          </template>

          <table>
            <tr>
              <td><strong>{{ $t('therms.crop') }} :</strong></td>
              <td>&nbsp;
                {{ item.crop.emoji }}
                {{ item.crop.name }}</td>
            </tr>
            <tr>
              <td><strong>{{ $t('therms.crop-type') }} :</strong></td>
              <td>&nbsp;
                {{ item.crop_type.name }}</td>
            </tr>
            <tr>
              <td><strong>{{ $t('therms.plot') }} :</strong></td>
              <td>&nbsp;
                {{ (item.plot.name !== item.plot.abbreviation ? item.plot.name + '('+item.plot.abbreviation+')' : item.plot.name) }}</td>
            </tr>
            <tr v-if="item.crop_grade !== null">
              <td><strong>{{ $t('therms.crop-grade') }} :</strong></td>
              <td>&nbsp; {{ item.crop_grade.name }}</td>
            </tr>
            <tr v-if="item.crop_packing !== null">
              <td><strong>{{ $t('therms.crop-packing') }} :</strong></td>
              <td>&nbsp; {{ item.crop_packing.name }}</td>
            </tr>
            <tr>

              <td><strong>{{ $t('therms.quantity') }} :</strong></td>
              <td>&nbsp; {{ item.quantity }}</td>
            </tr>
            <tr>
              <td><strong>{{ $t('therms.status') }} :</strong></td>
              <td>
                <b-badge :variant="status[1][item.is_valid ? 1 : 0]">
                  {{ status[0][item.is_valid ? 1 : 0] }}
                </b-badge>
              </td>
            </tr>
          </table>
          <div class="d-flex">
            <touch-icon-button
              size="md"
              class="mt-2 mr-50"
              @action="openUrl(item.pdf_url)"
            >
              <i class="fa-solid fa-file-invoice" />
            </touch-icon-button>
            <touch-icon-button
              size="md"
              color="secondary"
              class="mt-2 mr-50"
              @action="openUrl(item.xml_url)"
            >
              <i class="fa-solid fa-tag" />
            </touch-icon-button>
            <touch-icon-button
              size="md"
              color="secondary"
              class="mt-2 mr-50"
              @action="openUrl(item.json_url)"
            >
              <i class="fa-solid fa-code" />
            </touch-icon-button>
          </div>
        </app-collapse-item>
      </template>
    </app-collapse>
  </div>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import TouchIconButton from '@core/components/touch-button/TouchIconButton.vue'
import { BBadge } from 'bootstrap-vue'
import { Browser } from '@capacitor/browser'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    TouchIconButton,
    BBadge,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    sortedItems() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.items
        .sort((a, b) => new Date(a.datetime) - new Date(b.datetime))
    },
    status() {
      return [
        {
          1: 'Expedié', 0: 'Annulé',
        },
        {
          1: 'light-success', 0: 'light-danger',
        },
      ]
    },
  },
  watch: {},
  mounted() {
  },
  methods: {
    /**
     * @param {String} url
     * @returns {Promise<void>}
     */
    async openUrl(url) {
      await Browser.open({ url })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../../@core/scss/base/bootstrap-extended/_include';
@import '../../../../@core/scss/base/components/_include';

table tr td:nth-child(2) {
  padding-left: 20px;
}

.inputDate {
  position: relative;
  z-index: -100;
  top: 10px;
  opacity: 0;
}

</style>
