<template>
  <scroller>
    <NavButtonBar>
      <nav-button @action="$router.back()">
        <i class="fa-solid fa-angle-left" />
      </nav-button>
    </NavButtonBar>
    <h1 class="text-primary mb-2 pb-50 mt-2">
      {{ $t('pages.history.name') }}
    </h1>

    <div class="mt-3 mb-3">
      <template v-if="error">
        <div
          class="mb-5 text-muted text-center d-flex flex-column px-3"
        >
          <h1 class="mt-3 mb-2">
            <i class="fa-regular fa-cloud-exclamation" />
          </h1>
          {{ $t('pages.history.day.loading-error') }}

          <div>
            <TouchButton
              size="xs"
              class="mt-2"
              @action="$router.back()"
            >
              {{ $t('therms.go-back') }}
            </TouchButton>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="mb-1 pb-2 d-flex align-items-center position-relative">
          <touch-icon-button @action="chooseDate">
            <i class="fa-solid fa-calendar-days" />
          </touch-icon-button>

          <div class="ml-1 mb-0 ">
            <span class="lead font-weight-bold">{{ formatDate }}</span>
            <small class="d-block text-secondary">
              {{ !loader ? sheetsCount + ' expéditions' : $t('therms.loading') }}
            </small>
          </div>

          <input
            ref="date"
            v-model.lazy="date"
            type="date"
            class="inputDate"
          >
        </div>

        <template v-if="loader">
          <div class="d-flex align-items-center justify-content-center my-5 py-2">
            <b-spinner
              variant="primary"
              style="width: 3rem; height: 3rem;"
            />
          </div>
        </template>
        <template v-else>
          <template
            v-if="sheets !== null && sheetsCount>=1 && Object.values(stats).length>=1"
          >
            <template v-if="!isTablet">
              <ProductionCardResume
                v-for="production in stats"
                :id="production.id"
                :key="production.id"
                class="mb-1"
                :emoji="production.emoji"
                :name="production.name"
                :total-weight="production.total_weight"
                :quantity="production.quantity"
                :go-to="()=>{return goToOne(production)}"
              />
              <ProductionCardDistribution
                v-if="chart !== null"
                :series="chart.series"
                :labels="chart.labels"
                :colors="chart.colors"
              />
            </template>
            <template v-else>
              <b-row class="animate__animated animate__fadeInUp">
                <b-col
                  sm="12"
                  lg="5"
                >
                  <ProductionCardDistribution
                    v-if="chart !== null"
                    :series="chart.series"
                    :labels="chart.labels"
                    :colors="chart.colors"
                  />
                </b-col>
                <b-col
                  sm="12"
                  lg="7"
                >
                  <ProductionCardResume
                    v-for="production in stats"
                    :id="production.id"
                    :key="production.id"
                    class="mb-1"
                    :emoji="production.emoji"
                    :name="production.name"
                    :total-weight="production.total_weight"
                    :quantity="production.quantity"
                    :go-to="()=>{return goToOne(production)}"
                  />
                </b-col>
              </b-row>
            </template>
          </template>
          <template v-else>
            <div
              class="mb-5 text-muted text-center d-flex flex-column px-3"
            >
              <h1 class="mt-3 mb-2">
                <i class="fa-solid fa-empty-set" />
              </h1>
              {{ $t('pages.history.day.no-data') }}

              <div>
                <TouchButton
                  size="xs"
                  class="mt-2"
                  @action="$router.back()"
                >
                  {{ $t('therms.go-back') }}
                </TouchButton>
              </div>
            </div>
          </template>

          <HistorySheetDetails
            v-if="sheetsCount>=1"
            class="animate__animated animate__fadeInUp mb-2 pb-50"
            :items="sheets"
          />
        </template>
      </template>
    </div>
  </scroller>
</template>

<script>
import ProductionCardResume from '@/views/components/ProductionCardResume.vue'
import ProductionCardDistribution from '@/views/components/ProductionCardDistribution.vue'
import HistorySheetDetails from '@/views/pages/Histories/components/HistorySheetDetails.vue'
import NavButtonBar from '@core/layouts/components/app-navbar/NavButtonBar.vue'
import NavButton from '@core/layouts/components/app-navbar/NavButton.vue'
import TouchIconButton from '@core/components/touch-button/TouchIconButton.vue'
import TouchButton from '@core/components/touch-button/TouchButton.vue'
import { BSpinner, BRow, BCol } from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import { NAME_HISTORY_DATE_ONE } from '@/router/routes/history'
import { Toast } from '@capacitor/toast'

// eslint-disable-next-line func-names
const handleDateFocus = function () {
  if (this.getAttribute('type') === 'date') {
    this.showPicker()
  }
}

export default {
  components: {
    ProductionCardResume,
    ProductionCardDistribution,
    NavButtonBar,
    NavButton,
    TouchIconButton,
    TouchButton,
    BSpinner,
    HistorySheetDetails,
    BRow,
    BCol,
  },
  data() {
    return {
      // ui
      error: false,
      loader: false,

      // data
      date: null,
      sheets: null,
      stats: null,
    }
  },
  computed: {
    /**
     * @returns {boolean}
     */
    isTablet() {
      return window.isTablet()
    },
    /**
     * @returns {string}
     */
    formatDate() {
      return this
        .$moment(this.date)
        .format('DD/MM/YYYY')
    },
    /**
     * @returns {Number}
     */
    sheetsCount() {
      if (this.sheets === null) return 0

      return Object.values(this.sheets).length
    },

    /**
     * @return {{series: number[], colors: *[], labels: *[]}|null}
     */
    chart() {
      if (this.sheets === null || this.stats === null || this.sheets.length === 0 || Object.values(this.stats).length === 0) return null

      return {
        series: Object.values(this.stats)
          .map(element => Number(element.total_weight.toFixed(2))),
        colors: Object.values(this.stats)
          .map(element => element.color),
        labels: Object.values(this.stats)
          .map(element => element.name),
      }
    },
  },
  watch: {
    async date() {
      if (this
        .$moment(this.date)
        .isAfter(this.$moment())) {
        this.date = this.$moment()
          .toDate()
      }
    },
    /**
     * @returns {Promise<void>}
     */
    async formatDate() {
      try {
        const date = this.$moment(this.date)
          .locale('en')
          .format('YYYY-MM-DD')

        this.error = false
        this.loader = true
        this.sheets = null
        this.stats = null

        if (!await this.$store.dispatch('shipmentsHistory/has', date)) {
          const result = await this.$store.dispatch('shipmentsHistory/load', {
            date,
            force: true,
            byPassStorageCaching: true,
          })

          if (result === false) {
            setTimeout(() => {
              Toast.show({
                text: this.$t('pages.history.day.no-data-for-this-date')
                  .toString(),
              })
                .then()

              this.loader = false
            }, 200)
            return
          }
        } else {
          await this.$store.dispatch('shipmentsHistory/load', { date })
        }

        this.sheets = (await this.$store.dispatch('shipmentsHistory/getSheets', { date }))
        this.stats = await this.$store.dispatch('shipmentsHistory/getStats', { date })

        this.loader = false
      } catch (err) {
        this.loader = false
        this.error = true

        throw err
      }
    },
  },
  mounted() {
    this.date = this.$moment(this.$router.currentRoute.params.date)
      .locale('en')
      .format('YYYY-MM-DD')

    // eslint-disable-next-line func-names
    this.$refs.date.addEventListener('focus', handleDateFocus)
  },
  beforeDestroy() {
    this.$refs.date.removeEventListener('focus', handleDateFocus)
  },
  methods: {
    /**
     * @param {Number|String} v
     * @returns {*}
     */
    numberFormat(v) {
      return window.numberFormat(v)
    },
    chooseDate() {
      this.$refs.date.blur()
      setTimeout(() => {
        this.$refs.date.click()
        this.$refs.date.focus()
      }, 200)
    },
    /**
     * @param {ExpeditionSheet|any} element
     */
    goToOne(element) {
      this.$router.push({
        name: NAME_HISTORY_DATE_ONE,
        params: {
          date: this.$moment(this.date)
            .locale('en')
            .format('YYYY-MM-DD'),
          crop_id: element.id.toString(),
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../@core/scss/base/bootstrap-extended/_include';
@import '../../../@core/scss/base/components/_include';

table tr td:nth-child(2) {
  padding-left: 20px;
}

.inputDate {
  position: absolute;
  z-index: -100;
  bottom: 0;
  left: 0;
  opacity: 0;
}

</style>
